import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ShopDialogUiModule } from '@x/ecommerce-shop/app/core/shop-ui/components/shop-dialog-ui/shop-dialog-ui.module';
import { UtilsModule } from '@x/ecommerce-shop/app/core/utils/utils.module';
import { MembershipDialogComponent } from '@x/ecommerce-shop/app/membership/components/membership-dialog/membership-dialog.component';
import { MembershipService } from '@x/ecommerce-shop/app/membership/services/membership.service';

@NgModule({
  providers: [MembershipService],
  declarations: [MembershipDialogComponent],
  imports: [CommonModule, ShopDialogUiModule, MatButtonModule, UtilsModule],
})
export class MembershipSharedModule {}
